import React from 'react'
import { Navigate } from 'react-router-dom'
import { AuthService } from '../../services'

const AuthWrapper = ({ children, redirect }) => {
  const [authed, setAuthed] = React.useState(true)
  const [checkingAuth, setCheckingAuth] = React.useState(true)
  React.useEffect(() => {
    async function isLoggedIn() {
      try {
        const authed = (await AuthService.isLoggedIn()).data
        setAuthed(authed)
      } catch (err) {
        setAuthed(false)
        console.error(err)
      }

      setCheckingAuth(false)
    }
    isLoggedIn()
  })

  if (checkingAuth) return <div></div>



  // Aarontti: Redirect to login site if not logged in
  // Note: Should be server side, but it won't expose any sensitive information, and this project's already a hackjob
  let urlParameters = window.location.pathname.split('/')
  let isLoginPage = urlParameters.filter(part => part !== "").pop() == "login";

  let isWatchPage = window.location.pathname.startsWith('/w') || window.location.hash.startsWith('#/w');

  if ((!authed && !isLoginPage && !isWatchPage))
  {
    let baseUrl = window.location.protocol + "//" + window.location.host;
    let newUrl = baseUrl + "/#/login";
    window.location.href = newUrl;
  }



  if (!redirect) 
  {
    return React.cloneElement(children, { authenticated: authed })
  }
  else 
  {
    return authed ? React.cloneElement(children, { authenticated: authed }) : <Navigate to={redirect} />
  }
}

export default AuthWrapper
